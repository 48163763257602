const initialState = {
    validation: false
};

export default function FormValidation(state = initialState, action) {
    switch (action.type) {
        case 'SetValidationForm':
            return state = {validation: action.value};
        default:
            return state;
    }
}