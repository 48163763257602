/* eslint-disable import/prefer-default-export */
/* eslint-disable arrow-parens */
/* eslint-disable eol-last */
/* eslint-disable import/prefer-default-export */
import { combineReducers } from 'redux';

//reducer 
import Scrollvalue from './reducer/scrollvalue';
import Mousehover from './reducer/mousehover'
import MousePosition from './reducer/mousePosition'
import Loader from './reducer/loader'
import PosScroll from './reducer/posScroll'
import Orientation from './reducer/orientation'
import LocationPath from './reducer/location'
import FormValidation from './reducer/formvalidation'
import FilterTags from './reducer/tags'

const rootReducer = combineReducers({ PosScroll,MousePosition, Mousehover, Loader, Scrollvalue, Orientation, LocationPath, FormValidation, FilterTags });

// preloadedState will be passed in by the plugin
export default rootReducer;
