module.exports = [{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":2000,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"sizeByPixelDensity":false,"backgroundColor":"white","quality":50,"withWebp":false,"tracedSVG":false,"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Tactile-agence","short_name":"Tactile","start_url":"/","background_color":"#136f63","orientation":"portrait","theme_color":"#136f63","display":"standalone","icon":"src/favicon/icon.png","icons":[{"src":"/favicons/icon-48x48.png","sizes":"48x48","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-72x72.png","sizes":"72x72","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-96x96.png","sizes":"96x96","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-144x144.png","sizes":"144x144","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-192x192.png","sizes":"192x192","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-384x384.png","sizes":"384x384","type":"image/png","purpose":"maskable"},{"src":"/favicons/icon-512x512.png","sizes":"512x512","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"ed8eef250a0404d589cb05f72e0570eb"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#136f63","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
