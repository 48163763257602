import * as React from "react"

function IconBurger(props) {
  return (
    <svg width={20} height={20} viewBox="0 0 20 20" {...props}>
          <path data-name="Rectangle 37" d="M0 0h20v4H0z" />
          <path data-name="Rectangle 38" d="M0 8h20v4H0z" />
          <path data-name="Rectangle 39" d="M0 16h20v4H0z" />
    </svg>
  )
}

export default IconBurger