const initialState = {
    tags: [],
};

export default function FilterTags(state = initialState, action) {
    switch (action.type) {
        case 'setTags':
            return state = {tags: action.value};
        case 'resetTags' : 
        return state = initialState;
        default:
            return state;
    }
}