import React from 'react';
import { connect, useDispatch } from 'react-redux';

const Linkwrapper = ({children, mouseIcon, className}) => {

	const dispatch = useDispatch();

    // const [mousePosition, setMousePosition] = useState({ x: null, y: null });

    // const updateMousePosition = ev => {
    //   setMousePosition({ x: ev.clientX, y: ev.clientY });
    // };

    // useEffect(() => {
    //   window.addEventListener("mousemove", updateMousePosition);
  
    //   return () => window.removeEventListener("mousemove", updateMousePosition);
    // }, []);

    function Onhover(mouseIcon)
    {
        dispatch({ type: 'GetMouseState', icon: mouseIcon, value: 12});
    }

    function Outhover()
    {
        dispatch({ type: 'GetMouseState', value: 1 });
    }


    return ( 
        <>
        <div className="cursor_hide">
        <div className={className} onMouseEnter={()=>Onhover(mouseIcon)} onMouseLeave={()=>Outhover()}>
        {children}
        </div>
        </div>
        </>
    )
}

export default connect(state => ({
    scale: state.Mousehover.scale,
}), null)(Linkwrapper);