import React, { useEffect, useState } from "react";
import { useIntersection } from "react-use";
import * as animationDataEngagee from './engagee.json'
import * as animationDataHistoires from './des_histoires.json'
import * as animationDataStorymakers from './storymakers.json'
import * as animationDataInclusives from './inclusives.json'
import * as animationDataCreative from './creatives.json'
import * as animationDataEclairees from './eclairees.json'
import * as animationDataExpressions from './expressions.json'
import * as animationDataHistoiresFooter from './histoires_footer.json'
import * as animationDataRaconter from './raconter.json'
import * as animationDataNousplusVous from './vous_nous.json'
import * as animationDataProjets from './nos_histoires.json'
import * as animationDataContact from './un_cafe.json'
import * as animationDataMenu from "./menu_animation.json"
import * as animationDataEnvoyer from "./envoyer_OK.json"
import * as animationDataUnehistoire from "./une_histoire.json"

import * as animationLogoWhite from "./Tactile_white.json"
import * as animationLogoGreen from "./Tactile_Green.json"

import Lottie from 'react-lottie';
import { motion } from "framer-motion";

export const IntersectionContext = React.createContext({ inView: true });

export const WritingObserver = ({
  isReset,
  children,
  reset = isReset || false,
  text,
  delay,
  thresholdParam
}) => {
  const [inView, setInView] = useState(false);

  const intersectionRef = React.useRef(null);

  const [isStopped, setIsStopped] = useState(true);

  const AnimLogoGreen = {
    loop: false,
    autoplay: false,
    animationData: animationLogoGreen.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimLogoWhite = {
    loop: false,
    autoplay: false,
    animationData: animationLogoWhite.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimEnvoyer = {
    loop: false,
    autoplay: false,
    animationData: animationDataEnvoyer.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimUneHistoire = {
    loop: false,
    autoplay: false,
    animationData: animationDataUnehistoire.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };
  
  
  const AnimMenu = {
    loop: false,
    autoplay: false,
    animationData: animationDataMenu.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimEngagee = {
    loop: false,
    autoplay: false,
    animationData: animationDataEngagee.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimHistoire = {
    loop: false,
    autoplay: false,
    animationData: animationDataHistoires.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimHistoireFooter = {
    loop: false,
    autoplay: false,
    animationData: animationDataHistoiresFooter.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimStorymakers = {
    loop: false,
    autoplay: false,
    animationData: animationDataStorymakers.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimInclusives = {
    loop: false,
    autoplay: false,
    animationData: animationDataInclusives.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimCreatives = {
    loop: false,
    autoplay: false,
    animationData: animationDataCreative.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  const AnimEclairees = {
    loop: false,
    autoplay: false,
    animationData: animationDataEclairees.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimExpressions = {
    loop: false,
    autoplay: false,
    animationData: animationDataExpressions.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimRaconter = {
    loop: false,
    autoplay: false,
    animationData: animationDataRaconter.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const AnimNousplusVous = {
    loop: false,
    autoplay: false,
    animationData: animationDataNousplusVous.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const AnimProjets = {
    loop: false,
    autoplay: false,
    animationData: animationDataProjets.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


const AnimContact = {
    loop: false,
    autoplay: false,
    animationData: animationDataContact.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};


  const intersection = useIntersection(intersectionRef, {
    threshold: thresholdParam || 0.6
  });

  const [WindowScope, setWindowScope] = useState();

  useEffect(() => {
    const inViewNow = intersection && intersection.intersectionRatio > (thresholdParam || 0.59);
    const windowGlobal = typeof window !== 'undefined' && window;
    let isMounted = true; // note this flag denote mount status

    setWindowScope(windowGlobal);

    if (inViewNow && isMounted) {
      windowGlobal.setTimeout(() => setIsStopped(false), delay || 0);
    } else if (reset) {
      setIsStopped(true);
    }
    return () => { isMounted = false };
  }, [intersection, reset, thresholdParam, WindowScope]);

  let WindowScopeValue = WindowScope;

  return (
    <IntersectionContext.Provider value={{ inView }}>
      <div ref={intersectionRef} className=" pointer-events-none">
      {text === "LogoGreen" &&
          <Lottie options={AnimLogoGreen}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
      {text === "LogoWhite" &&
          <Lottie options={AnimLogoWhite}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
      {text === "UneHistoire" &&
          <Lottie options={AnimUneHistoire}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
      {text === "envoyer" &&
          <Lottie options={AnimEnvoyer}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
      {text === "menu" &&
          <Lottie options={AnimMenu}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
      {text === "nos_histoires" &&
          <Lottie options={AnimNousplusVous}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
        {text === "projets" &&
          <Lottie options={AnimProjets}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
        {text === "contact" &&
          <Lottie options={AnimContact}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="100%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
        {text === "engagée" &&
          <Lottie options={AnimEngagee}
            height="auto"
            href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
            width="60%"
            speed={1.7}
            isStopped={isStopped}
            isPaused={isStopped}
          />
        }
        {text === "histoires" &&
          <motion.div initial={{}} animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 60,
              duration: 1.5,
              staggerChildren: 0.35
            }}>
            <Lottie options={AnimHistoire}
              height="auto"
              width="70%"
              href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
              speed={1.7}
              isStopped={isStopped}
              isPaused={isStopped}
            />
          </motion.div>
        }
        {text === "histoires_footer" &&
          <motion.div initial={{}} animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 60,
              duration: 1.5,
              staggerChildren: 0.35
            }}>
            <Lottie options={AnimHistoireFooter}
              height="auto"
              width="80%"
              speed={1.7}
              href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
              isStopped={isStopped}
              isPaused={isStopped}
            />
          </motion.div>
        }
        {text === "storymakers" &&
          <motion.div initial={{}} animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 60,
              duration: 1.5,
              staggerChildren: 0.35
            }}>
            <Lottie options={AnimStorymakers}
              height="auto"
              width="80%"
              speed={1.7}
              href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
              isStopped={isStopped}
              isPaused={isStopped}
            />
          </motion.div>
        }
        {text === "inclusives" &&
          <motion.div initial={{}} animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 60,
              duration: 1.5,
              staggerChildren: 0.35
            }}>
            <Lottie options={AnimInclusives}
              height="auto"
              width="100%"
              speed={1.7}
              href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
              isStopped={isStopped}
              isPaused={isStopped}
            />
          </motion.div>
        }
        {text === "creatives" &&
          <motion.div initial={{}} animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 60,
              duration: 1.5,
              staggerChildren: 0.35
            }}>
            <Lottie options={AnimCreatives}
              height="auto"
              width="100%"
              speed={1.7}
              href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
              isStopped={isStopped}
              isPaused={isStopped}
            />
          </motion.div>
        }
        {text === "eclairees" &&
          <motion.div initial={{}} animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 60,
              duration: 1.5,
              staggerChildren: 0.35
            }}>
            <Lottie options={AnimEclairees}
              height="auto"
              width="100%"
              speed={1.7}
              href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
              isStopped={isStopped}
              isPaused={isStopped}
            />
          </motion.div>
        }
        {text === "expressions" &&
          <motion.div initial={{}} animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 60,
              duration: 1.5,
              staggerChildren: 0.35
            }}>
            <Lottie options={AnimExpressions}
              height="auto"
              width="100%"
              speed={1.7}
              href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
              isStopped={isStopped}
              isPaused={isStopped}
            />
          </motion.div>
        }
        {text === "raconter" &&
          <motion.div initial={{}} animate={{ y: 0, opacity: 1 }}
            transition={{
              type: 'spring',
              damping: 15,
              stiffness: 60,
              duration: 1.5,
              staggerChildren: 0.35
            }}>
            <Lottie options={AnimRaconter}
              height="auto"
              width="100%"
              speed={1.7}
              href={WindowScopeValue !== undefined && WindowScopeValue.location.href}
              isStopped={isStopped}
              isPaused={isStopped}
            />
          </motion.div>
        }
      </div>
    </IntersectionContext.Provider>
  );
};