const initialState = {
    alpha: 0,
    gamma: 0,
    beta:0
};

export default function Orientation(state = initialState, action) {
    switch (action.type) {
        case 'SetAlpha':
            return state = {type: action.icon, alpha: action.value};
        case 'SetBeta':
            return state = {type: action.icon, beta: action.value};
        case 'SetGamma':
             return state = {type: action.icon, gamma: action.value};
        default:
            return state;
    }
}