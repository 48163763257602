const initialState = {
    type: "new",
    scale: 1,
};

export default function Mousehover(state = initialState, action) {
    switch (action.type) {
        case 'GetMouseState':
            return state = {type: action.icon, scale: action.value};
        default:
            return state;
    }
}