import React from "react"
import Lottie from 'react-lottie';
import * as animationData from './loading_animation.json'
import { useDispatch } from 'react-redux';
import { motion, AnimatePresence } from 'framer-motion';
import { connect } from 'react-redux';

const Loader = ({ loading }) => {

    const dispatch = useDispatch();

    const defaultOptions = {
        loop: false,
        autoplay: true,
        animationData: animationData.default,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const PageTransition = {
        initial: {
            opacity: 1,
        },
        enter: {
            opacity: 1,
            transition: {
                delay: 0,
                duration: 0,
            },
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 0.2,
                duration: 0.35,
            },
        },
    };

    return (
        <>
            <div>
                <AnimatePresence>
                    {loading &&
                        <motion.div
                            key="loader"
                            variants={PageTransition}
                            initial="initial"
                            animate="enter"
                            exit="exit"
                            className="flex pointer-events-none items-center justify-center z-110 w-full h-full top-0 bg-white fixed">
                            <div className="w-screen md:w-2/4">
                                <Lottie options={defaultOptions}
                                        height="auto"
                                        width="95%"
                                        speed={1.5}
                                        eventListeners={[
                                            {
                                                eventName: 'complete',
                                                callback: () => (dispatch({ type: 'ChangeLoadingState', value: false }), sessionStorage.setItem('Loaded', true))
                                            },
                                        ]}
                                />
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </>
    )
}

export default connect(state => ({
    loading: state.Loader.loading,
}), null)(Loader);
