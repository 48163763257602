const initialState = {
    scrollvalue: 0,
};

export default function PosScroll(state = initialState, action) {
    switch (action.type) {
        case 'GetScroll':
            return state = {scrollvalue: action.value};
        default:
            return state;
    }
}