import React, { useState, useEffect } from 'react';
import { motion } from "framer-motion";
import "./style.css"
import * as animationDataNew from './cursor_new.json'
import * as animationDataScroll from './cursor_scroll.json'
import * as animationDataDiscover from './cursor_projects_discovers.json'
import * as animationDataLinkedin from './cursor_linkedin.json'
import * as animationDataInstagram from './cursor_instagram.json'
import * as animationDataTeam from './cursor_team.json'
import * as animationDataContact from './cursor_contact.json'
import * as animationDataHome from './cursor_home.json'
import * as animationDataProject from './cursor_project.json'
import * as animationDataMenu from './cursor_menu.json'
import * as animationDataTop from './cursor_top.json'
import * as animationDataInfos from './cursor_infos.json'
import * as animationDataOk from './cursor_ok.json'
import * as animationDataLink from './cursor_link.json'

import { isBrowser } from "react-device-detect";

import { connect, useDispatch } from 'react-redux';

import Lottie from 'react-lottie';

const Cursor = ({ scale, MouseType }) => {

  const [WindowScopeCursor, setWindowScopeCursor] = useState();

  const [mousePosition, setMousePosition] = useState({ x: null, y: null });
  const [mouseVisibility, setMouseVisibility] = useState({ visibility: 1 });
  const [navigatorState, setNavigatorState] = useState();

  const dispatch = useDispatch();

  const updateMousePosition = ev => {
    setMousePosition({ x: ev.clientX, y: ev.clientY, visibility: true });
  };

  const updateMouseVisibility = () => {
    setMouseVisibility({ visibility: 0 });
  };

  const updateMouseVisibility2 = () => {
    setMouseVisibility({ visibility: 1 });
  };

  const OptionsCursorNew = {
    loop: false,
    autoplay: true,
    animationData: animationDataNew.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorTop = {
    loop: false,
    autoplay: true,
    animationData: animationDataTop.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorInfos = {
    loop: false,
    autoplay: true,
    animationData: animationDataInfos.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorMenu = {
    loop: false,
    autoplay: true,
    animationData: animationDataMenu.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorProject = {
    loop: false,
    autoplay: true,
    animationData: animationDataProject.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorOk = {
    loop: false,
    autoplay: true,
    animationData: animationDataOk.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorLink = {
    loop: false,
    autoplay: true,
    animationData: animationDataLink.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorHome = {
    loop: false,
    autoplay: true,
    animationData: animationDataHome.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorScroll = {
    loop: false,
    autoplay: true,
    animationData: animationDataScroll.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorDiscoverProjects = {
    loop: false,
    autoplay: true,
    animationData: animationDataDiscover.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorlinkedin = {
    loop: false,
    autoplay: true,
    animationData: animationDataLinkedin.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorinstagram = {
    loop: false,
    autoplay: true,
    animationData: animationDataInstagram.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorTeam = {
    loop: false,
    autoplay: true,
    animationData: animationDataTeam.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  const OptionsCursorContact = {
    loop: false,
    autoplay: true,
    animationData: animationDataContact.default,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  useEffect(() => {
    const windowGlobal = typeof window !== 'undefined' && window;
    const navigatorGlobal = typeof navigator !== 'undefined' && navigator;

    setWindowScopeCursor(windowGlobal);
    setNavigatorState(navigatorGlobal);

    const updateMouseIcon = ev => {
      scale !== 0 && dispatch({ type: 'GetMouseState', value: 1 });
    };

    window.addEventListener("mousemove", updateMousePosition);
    window.addEventListener("click", updateMouseIcon);
    document.addEventListener("mouseleave", updateMouseVisibility);
    document.addEventListener("mouseenter", updateMouseVisibility2);

    return () => {
      window.removeEventListener("mousemove", updateMousePosition);
      window.removeEventListener("click", updateMouseIcon);
      window.removeEventListener("mouseleave", updateMouseVisibility);
      window.removeEventListener("mouseenter", updateMouseVisibility2);
    }
  }, [scale, WindowScopeCursor]);

  let navigatorGlob = navigatorState;
  let WindowScopeValueCursor = WindowScopeCursor;

  let isIOS = navigatorGlob !== undefined && (/iPad|iPhone|iPod/.test(navigatorGlob.platform) || (navigatorGlob.platform === 'MacIntel' && navigatorGlob.maxTouchPoints > 1))

  return (
    <>
      <div>
        {(isBrowser && isIOS === false) &&
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: mouseVisibility.visibility }}
            transition={{ type: "tween", duration: 0.2, delay: 0.2 }}
          >
            <motion.div
              className={`cursor fixed z-100 ${scale === 1 ? "" : "cursor_transparent"}`}
              animate={{ x: mousePosition.x, y: mousePosition.y, scale: scale }}
              transition={{
                type: "spring", duration: 0.005, damping: 100,
                stiffness: 400,
                mass: 0.3,
              }}
            >
              {MouseType === "project" &&
                <Lottie options={OptionsCursorProject}
                  height="auto"
                  width="100%"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                />
              }
              {MouseType === "home" &&
                <Lottie options={OptionsCursorHome}
                  height="auto"
                  width="100%"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                />
              }
              {MouseType === "new" &&
                <Lottie options={OptionsCursorNew}
                  height="auto"
                  width="100%"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                />
              }
              {MouseType === "team" &&
                <Lottie options={OptionsCursorTeam}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "contact" &&
                <Lottie options={OptionsCursorContact}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "scroll" &&
                <Lottie options={OptionsCursorScroll}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "discover" &&
                <Lottie options={OptionsCursorDiscoverProjects}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "linkedin" &&
                <Lottie options={OptionsCursorlinkedin}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "instagram" &&
                <Lottie options={OptionsCursorinstagram}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "menu" &&
                <Lottie options={OptionsCursorMenu}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "top" &&
                <Lottie options={OptionsCursorTop}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "infos" &&
                <Lottie options={OptionsCursorInfos}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "ok" &&
                <Lottie options={OptionsCursorOk}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
              {MouseType === "link" &&
                <Lottie options={OptionsCursorLink}
                  height="auto"
                  href={WindowScopeValueCursor !== undefined && WindowScopeValueCursor.location.href}
                  width="100%"
                />
              }
            </motion.div>
          </motion.div>
        }
      </div>
    </>
  )
}

export default connect(state => ({
  scale: state.Mousehover.scale,
  MouseType: state.Mousehover.type,
}), null)(Cursor);

