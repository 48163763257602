const initialState = {
    loading: true,
};

export default function Loader(state = initialState, action) {
    switch (action.type) {
        case 'ChangeLoadingState':
            return state = {loading: action.value};
        default:
            return state;
    }
}