const initialState = {
    path: "/",
};

export default function LocationPath(state = initialState, action) {
    switch (action.type) {
        case 'SetPathLocation':
            return state = {type: action.icon, path: action.value};
        default:
            return state;
    }
}