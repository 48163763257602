import React from 'react';
import Layout from './src/layout';
import ReactGA from 'react-ga';
import { globalHistory } from '@reach/router';

ReactGA.initialize('G-ZZTYDHV1RH');

export { default as wrapRootElement } from './src/redux/ReduxWrapper';

export const onInitialClientRender = () => {
  /**
   * This is a workaround for a bug in Gatsby
   *
   * See https://github.com/gatsbyjs/gatsby/issues/8357 for more details
   */
  globalHistory._onTransitionComplete();
  // window.addEventListener('popstate', () =>
  //   window.location.href = window.location.href
  // )
}

//Call Google Analytics on route update
export const onRouteUpdate = () => {
  ReactGA.pageview(window.location.pathname + window.location.search);
};

//wrap redux provider to layout
export const wrapPageElement = ({ element, props }) => <Layout {...props}>{element}</Layout>;

//intersection-observer polyfill
export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`);
  }
}

//scroll position delay between pages for transition
//const transitionDelay = 500;


export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {

  return true;
};  
