const initialState = {
    scrollHeight: 0,
    windowHeight:0
};

export default function Scrollvalue(state = initialState, action) {
    switch (action.type) {
        case 'SetScrollValue':
            return state = {scrollHeight: action.value};
        case 'SetWindowHeight':
                return state = {windowHeight: action.value};
        default:
            return state;
    }
}