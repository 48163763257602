import React, { useEffect } from 'react';
import "./style.css"
import Linkwrapper from "../linkWrapper/linkWrapper"
import { connect } from 'react-redux';
import { motion, useSpring, useTransform, useViewportScroll, AnimatePresence } from 'framer-motion';
import { isBrowser, isMobile } from "react-device-detect";

const Header = ({ LocationPath, location }) => {

  const { scrollY } = useViewportScroll();

  const yvalue = useTransform(scrollY, [250, 450], [0, 400]);
  const yspring = useSpring(yvalue, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  const yvalueMobile = useTransform(scrollY, [20, 60], [0, 400]);
  const yspringMobile = useSpring(yvalueMobile, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  function scrollView() {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth',
    });

    /*const showreel = document.getElementById('showreel')
    console.log('video showreel', showreel)
    if( showreel ) {
      showreel.pause()
    }*/
  }

  // useEffect(() => {
  //   location === "/" ?
  // }, [location]);

  return (
      <>
        <div>
          <motion.div
              style={{ y: isBrowser ? yspring : yspringMobile }}
              className="flex bottom-0 pointer-events-none font-Gilroy_bold justify-center mb-4 md:mb-6 items-center w-screen h-12 fixed z-50 text-xs md:text-xs tracking-widest invert text-white"
          >
            <Linkwrapper mouseIcon="scroll">
              <div className="p-2 pointer-events-auto" onClick={() => scrollView()}>
                SCROLL
              </div>
            </Linkwrapper>
          </motion.div>
        </div>
      </>
  )
}

export default connect(state => ({
  LocationPath: state.LocationPath.path
}), null)(Header);
