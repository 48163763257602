import React, { useState, useEffect } from 'react';
import Link from "gatsby-link";
import LogoTactile from "../../header/logo";
import Linkwrapper from "../../linkWrapper/linkWrapper"
import { motion, AnimatePresence } from 'framer-motion';
import { isBrowser } from "react-device-detect";
import IconBurger from './icon_burger';
import { WritingObserver } from '../../animations/writing';
import greyForm from "../../../images/UI/formes/menu/forme-gris.png";
import bronzeForm from "../../../images/UI/formes/menu/forme-cuivre.png";

const Navigation = ({ data, location }) => {

    const [NavOpen, setNavState] = useState(false);
    const [WindowScope, setWindowScope] = useState();
    const [isHoverAgence, setIsHoverAgence] = useState(false);
    const [isHoverProjets, setIsHoverProjets] = useState(false);
    const [isHoverContact, setIsHoverContact] = useState(false);

    function ToogleState(NavOpen) {
        setNavState(!NavOpen);
    }

    function ToogleStateDelay() {
        setNavState(false);
        setIsHoverAgence(false);
        setIsHoverProjets(false);
        setIsHoverContact(false);
    }

    function ToogleHoverAgence(isHoverAgence) {
        setIsHoverAgence(!isHoverAgence);
    }

    function ToogleHoverProjets(isHoverProjets) {
        setIsHoverProjets(!isHoverProjets);
    }

    function ToogleHoverContact(isHoverContact) {
        setIsHoverContact(!isHoverContact);
    }

    const NavTransition = {
        initial: {
            opacity: 0,
        },
        enter: {
            opacity: 1,
            transition: {
                duration: 0.35,
            },
        },
        exit: {
            opacity: 0,
            transition: {
                delay: 0,
                duration: 0.3,
                when: "beforeChildren"
            },
        },
    };

    useEffect(() => {
        const windowGlobal = typeof window !== 'undefined' && window;
        setWindowScope(windowGlobal);
    }, [WindowScope]);

    let WindowScopeValue = WindowScope;

    return (
        <>
            <div>
                {NavOpen === false &&
                    <AnimatePresence>
                        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { duration: 0.35, } }} exit={{ opacity: 0, transition: { duration: 0.35, } }} className="exclusion fixed right-0 top-0 z-100 cursor-pointer mt-10 mr-10" onClick={() => ToogleState(NavOpen)}>
                            <Linkwrapper mouseIcon="menu">
                                <IconBurger className="rose_fill" />
                            </Linkwrapper>
                        </motion.div>
                    </AnimatePresence>
                }
                {NavOpen === true &&
                    <div className="fixed right-0 top-0 z-100 h-5 w-5 cursor-pointer mt-10 mr-10 " onClick={() => ToogleState(NavOpen)}>
                        <Linkwrapper mouseIcon="top">
                            <WritingObserver text="menu" />
                        </Linkwrapper>
                    </div>
                }
                <AnimatePresence>
                    {NavOpen === true &&
                        <motion.div
                            key={Navigation}
                            variants={NavTransition}
                            initial="initial"
                            animate="enter"
                            exit="exit"
                            className="select-none right-0 flex w-screen h-screen justify-center items-center top-0 fixed menu_bg z-90"
                        >
                            <>
                                <header className="fixed top-0 pointer-events-none ">
                                    <motion.div className="flex top-0 mt-6 justify-center mb-6 items-center w-screen h-12">
                                        <Linkwrapper mouseIcon="home">
                                            <Link to="/">
                                                <div onClick={() => ToogleStateDelay(NavOpen)}>
                                                    <LogoTactile className=" w-24 h-auto text-white fill-current pointer-events-auto" />
                                                </div>
                                            </Link>
                                        </Linkwrapper>
                                    </motion.div>
                                </header>
                                <div className="flex flex-col items-center overflow-visible justify-center">
                                    <Link to="/agence" className="cursor-pointer overflow-visible relative">
                                        <div onClick={() => ToogleStateDelay(NavOpen)} onMouseLeave={() => ToogleHoverAgence(isHoverAgence)} onMouseEnter={() => ToogleHoverAgence(isHoverAgence)} className="md:h-16 xxl:h-20  flex-row gap-2 items-center justify-center relative text-5xl md:text-6xl xxl:text-7xl font-Gilroy_ExtraBoldItalic w-full text-center transition-colors duration-500 text-white hover:text-black inline-flex"><div className="h-full w-auto flex items-start justify-start"><div className="absolute left-0 -ml-5 h-full mt-1 tracking-widest top-0 text-sm font-Gilroy_bold">01</div></div><div>L'agence</div></div>
                                        {isHoverAgence === true &&
                                            <>
                                                <div className="absolute -ml-5 flex items-center justify-center overflow-visible top-0 left-0 w-110 h-full pointer-events-none">
                                                    <WritingObserver text="nos_histoires" />
                                                </div>
                                            </>
                                        }
                                    </Link>
                                    <Link to="/projets" className="cursor-pointer overflow-visible relative">
                                        <div onClick={() => ToogleStateDelay(NavOpen)} onMouseLeave={() => ToogleHoverProjets(isHoverProjets)} onMouseEnter={() => ToogleHoverProjets(isHoverProjets)} className="md:h-16 xxl:h-20 flex-row gap-2 items-center justify-center relative text-5xl md:text-6xl xxl:text-7xl font-Gilroy_ExtraBoldItalic w-full text-center transition-colors duration-500 text-white hover:text-black inline-flex"><div className="h-full w-auto flex items-start justify-start"><div className="absolute left-0 -ml-5 h-full mt-1 tracking-widest top-0 text-sm font-Gilroy_bold">02</div></div><div>Nos projets</div></div>
                                        {isHoverProjets === true &&
                                            <>
                                                <div className="absolute flex -ml-5 items-center justify-center overflow-visible top-0 left-0 w-110 h-full pointer-events-none">
                                                    <WritingObserver text="projets" />
                                                </div>
                                            </>
                                        }
                                    </Link>
                                    <Link to="/contact" className="cursor-pointer overflow-visible relative">
                                        <div onClick={() => ToogleStateDelay(NavOpen)} onMouseLeave={() => ToogleHoverContact(isHoverContact)} onMouseEnter={() => ToogleHoverContact(isHoverContact)} className="md:h-16 xxl:h-20  flex-row gap-2 items-center justify-center relative text-5xl md:text-6xl xxl:text-7xl font-Gilroy_ExtraBoldItalic w-full text-center transition-colors duration-500 text-white hover:text-black inline-flex"><div className="h-full w-auto flex items-start justify-start"><div className="absolute left-0 -ml-5 h-full mt-1 tracking-widest top-0 text-sm font-Gilroy_bold">03</div></div><div>Contact</div></div>
                                        {isHoverContact === true &&
                                            <>
                                                <div className="absolute flex -ml-5 items-center justify-center overflow-visible top-0 left-0 w-110 h-full pointer-events-none">
                                                    <WritingObserver text="contact" />
                                                </div>
                                            </>
                                        }
                                    </Link>
                                </div>
                                {isBrowser &&
                                    <>
                                        <div className="flex  font-Gilroy_semibold left-0 justify-center md:ml-6 items-center h-screen w-8 md:w-12 text-vert fixed text-white z-100 text-xxs md:text-xs tracking-wider">
                                            20 rue de l ’Hôtel de Ville - 92200 Neuilly-sur-Seine
                                    </div>
                                        <div className="flex right-0 font-Gilroy_bold text-white justify-center md:mr-6 items-center h-screen w-8 md:w-12 fixed text-vert-2 z-100 text-xs md:text-base tracking-wider">
                                            <div className="flex items-center justify-center h-full w-8 relative overflow-hidden">
                                                <motion.ul className="flex h-full items-center relative pointer-events-none align-middle justify-center text-xxs md:text-xs">
                                                    <motion.li className="absolute opacity-0" initial={{ opacity: 0, x: 10 }} animate={{ x: location === "/" ? 0 : 10, opacity: location === "/" ? 1 : 0 }}>Bienvenue</motion.li>
                                                    <motion.li className="absolute opacity-0" initial={{ opacity: 0, x: 10 }} animate={{ x: location.indexOf("/agence") === 0 ? 0 : 10, opacity: location.indexOf("/agence") === 0 ? 1 : 0 }}>Agence</motion.li>
                                                    <motion.li className="absolute opacity-0" initial={{ opacity: 0, x: 10 }} animate={{ x: location.indexOf("/projets") === 0 ? 0 : 10, opacity: location.indexOf("/projets") === 0 ? 1 : 0 }}>Projets</motion.li>
                                                    <motion.li className="absolute opacity-0" initial={{ opacity: 0, x: 10 }} animate={{ x: location.indexOf("/contact") === 0 ? 0 : 10, opacity: location.indexOf("/contact") === 0 ? 1 : 0 }}>Contact</motion.li>
                                                    {/* <motion.li className="absolute opacity-0" initial={{opacity:0, x:10}} animate={{x : location.pathname==="/projets/tags/*" ? 0 : 10, opacity: location.pathname==="/projets/tags/*" ? 1 : 0}}>Tags</motion.li> */}
                                                </motion.ul>
                                            </div>
                                        </div>
                                    </>
                                }
                            </>
                            <div className="absolute bottom-0 left-0 w-full h-20 flex items-center justify-center ">
                                <ul className="w-3/4 flex flex-row gap-2 items-center justify-center font-Gilroy_extrabold text-menu text-white tracking-widest uppercase mb-0">
                                    <Linkwrapper mouseIcon="infos">
                                        <li className="mb-0 relative"><div className="social-link"><a href="https://www.facebook.com/AgenceTactile" name="facebook" className="w-full" rel="noopener noreferrer" target="_blank">Facebook</a></div></li>
                                    </Linkwrapper>
                                    <li>-</li>
                                    <Linkwrapper mouseIcon="infos">
                                        <li className="mb-0 relative" ><div className="social-link"><a href="https://www.instagram.com/agence_tactile" name="Instagram" className="w-full" rel="noopener noreferrer" target="_blank">Instagram</a></div></li>
                                    </Linkwrapper>
                                    <li>-</li>
                                    <Linkwrapper mouseIcon="infos">
                                        <li className="mb-0 relative"><div className="social-link"><a href="https://vimeo.com/user4933038" name="Vimeo" className="w-full" rel="noopener noreferrer" target="_blank">Vimeo</a></div></li>
                                    </Linkwrapper>
                                </ul>
                            </div>
                            {isBrowser &&
                                <div className="absolute left-0 bottom-0 mb-0 md:mb-40 xl:ml-10 xxl:ml-32 pointer-events-none">
                                    <img className=""
                                        src={greyForm}
                                        alt="Forme">
                                    </img>
                                </div>
                            }
                            <div className="absolute right-0 top-0 mr-0 md:mr-48 pointer-events-none">
                                <img className=""
                                    src={bronzeForm}
                                    alt="Forme">
                                </img>
                            </div>
                        </motion.div>
                    }
                </AnimatePresence>
            </div>
        </>
    )
}

export default Navigation