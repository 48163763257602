/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types"
import { motion, AnimatePresence, useSpring, useTransform, useViewportScroll } from 'framer-motion';
import { connect } from 'react-redux';
import "../components/UI/TopButton/button.css";
import { useDispatch } from 'react-redux';

import Header from "../components/header/header"
import Cursor from "../components/cursor/cursor"
import Loader from "../components/loader/loader"
import Linkwrapper from "../components/linkWrapper/linkWrapper"
import { isBrowser, isMobile } from "react-device-detect";
import LogoTactile from "../components/header/logo"
import Link from "gatsby-link" // not error 

import "./layout.css"
import "../styles/main.scss"
import Navigation from "../components/UI/menu/menu";
import { WritingObserver } from "../components/animations/writing";
import { TransitionGroup, CSSTransition, SwitchTransition } from 'react-transition-group';
import { Awwards } from "../components/UI/awwards/awwards";


const Layout = ({ location, children }) => {
  const { scrollY } = useViewportScroll();
  const windowGlobal = typeof window !== 'undefined' && window
  const dispatch = useDispatch();

  const [WindowPathname, setWindowPathname] = useState("undefined");
  const [documentState, setDocumentState] = useState();
  const [windowState, setWindowState] = useState();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {

    const DocumentGlobal = typeof document !== 'undefined' && document
    const windowGlobal = typeof window !== 'undefined' && window
    dispatch({ type: 'ChangeLoadingState', value: false });
    console.log(sessionStorage.getItem('Loaded'));

    setIsMounted(true);
    setDocumentState(DocumentGlobal);
    setWindowState(windowGlobal);
    setWindowPathname(location.pathname);

  }, [isMounted]);

  const duration = 0.5;

  const PageTransition = {
    initial: {
      opacity: 0,
    },
    enter: {
      opacity: 1,
      transition: {
        duration: duration,
        delay: duration,
        when: 'beforeChildren',
      },
    },
    exit: {
      opacity: 0,
      transition: { duration: duration },
    },
  }

  const buttonAnims = {
    hidden: {
      y: 100,
    },
    show: {
      y: 0,
    },
    tap: {
      scale: 1,
      transition: {
        duration: 0.2,
        type: 'spring',
        damping: 20,
        stiffness: 400,
      }
    },
    hover: {
      scale: 2,
      transition: {
        type: 'tween',
      }
    },
  };

  function handleClickTop() {
    windowGlobal.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  const opacity = useTransform(scrollY, [500, 550], [150, 70]);
  const yspringButton = useSpring(opacity, { damping: 15, stiffness: 50, mass: 0.2 })

  const opacityMobile = useTransform(scrollY, [500, 550], [150, 35]);
  const yspringButtonMobile = useSpring(opacityMobile, { damping: 15, stiffness: 50, mass: 0.2 })


  //logo anims
  const yvalue2 = useTransform(scrollY, [0, 150], [0, -200]);
  const yspring2 = useSpring(yvalue2, { damping: 15, stiffness: 10, mass: 0.2, restSpeed: 0.5 })

  const yvalue = useTransform(scrollY, [250, 450], [1, 0]);
  const yspring = useSpring(yvalue, { damping: 50, stiffness: 100, mass: 2, })
  // useEffect(() => {
  //   console.log(location);
  // }, [location ]);

  let PageLoaded = documentState;
  let WindowLoaded = windowState;

  const isIE = PageLoaded !== undefined && (/*@cc_on!@*/false || !!PageLoaded.documentMode);
  const isEdge = WindowLoaded !== undefined && (!isIE && !!WindowLoaded.StyleMedia);

  return (
    <>
      <div><Loader /></div>
      <div><Cursor /></div>
      <div>
        {isIE || isEdge &&
          <div className="flex-row font-Gilroy_medium text-white fixed z-110 top-0 left-0 h-screen w-screen bg-tactileGreen flex items-center justify-center">
            <div className="w-1/2 h-auto"><WritingObserver reset={true} text="LogoWhite" /></div>
            <div className="w-1/2 mb-5">Veuillez installer Edge version Chromium</div>
          </div>
        }
      </div>
      {(location?.pathname?.indexOf('trackrecord') === -1 && location?.pathname?.indexOf('casestudy') === -1) &&
        <div>
          {isMobile &&
          <div className="portrait-mode flex-row font-Gilroy_medium text-white fixed z-110 top-0 left-0 h-screen w-screen bg-tactileGreen flex items-center justify-center">
            <div className="w-1/2 h-auto"><WritingObserver reset={true} text="LogoWhite" /></div>
            <div className="w-1/2 mb-5">Ce site ne supporte pas le mode paysage.</div>
          </div>
          }
        </div>
      }
      <header className="fixed z-60 exclusion pointer-events-none ">
        {(location.pathname !== "/" && location.pathname !== "undefined") &&
          <motion.div style={{ y: yspring2, opacity: isBrowser ? 1 : yspring }} className="flex top-0 mt-6 justify-center mb-6 items-center w-screen h-12">
            <Linkwrapper mouseIcon="home">
              <Link to="/">
                <LogoTactile className=" w-24 h-auto rose_fill pointer-events-auto" />
              </Link>
            </Linkwrapper>
          </motion.div>
        }
      </header>
      <Header location={location.pathname} />
      <div>
        {!isMobile &&
          <Awwards />
        }
      </div>
      <Navigation location={location.pathname} />
      <div>
        <motion.div
          whileTap="tap"
          variants={buttonAnims}
          className="mr-2 md:mr-6 exclusion z-80 TopButton h-10 md:h-12 w-auto md:w-12"
          style={{ y: isBrowser ? yspringButton : yspringButtonMobile }}
          onClick={() => handleClickTop()}
        >
          <Linkwrapper mouseIcon="top">
            <div
              className="mr-2 md:h-full lg:mr-10"
            >
              <svg id="Top_arrow" x="0px" y="0px" viewBox="0 0 22.627 14.143">
                <g fill="#ec909c">
                  <path
                    d="M11.314 0l11.314 11.314-2.829 2.828L8.486 2.828z"
                  />
                  <path
                    d="M14.142 2.829L2.828 14.143 0 11.314 11.314.001z"
                  />
                </g>
              </svg>
            </div>
          </Linkwrapper>
        </motion.div>
      </div>
      <div className="flex select-none pointer-events-none text-white invert font-Gilroy_bold left-0 justify-center md:ml-6 items-center h-screen w-8 md:w-12 fixed text-vert z-70 text-xxs md:text-xs tracking-wider">
        20 rue de l ’Hôtel de Ville - 92200 Neuilly-sur-Seine
          </div>
      <div className="flex right-0 font-Gilroy_bold text-white invert justify-center md:mr-6 items-center h-screen w-8 md:w-12 fixed text-vert-2 z-70 text-xs md:text-base tracking-wider">
        <div className="flex items-center justify-center h-full w-8 relative overflow-hidden invert">
          <motion.ul className="flex h-full items-center relative pointer-events-none align-middle justify-center text-xxs md:text-xs">
            <motion.li className="absolute opacity-0" initial={{ opacity: 0, x: 10 }} animate={{ x: location.pathname === "/" ? 0 : 10, opacity: location.pathname === "/" ? 1 : 0 }}>Bienvenue</motion.li>
            <motion.li className="absolute opacity-0" initial={{ opacity: 0, x: 10 }} animate={{ x: location.pathname.indexOf("/agence") === 0 ? 0 : 10, opacity: location.pathname.indexOf("/agence") === 0 ? 1 : 0 }}>Agence</motion.li>
            <motion.li className="absolute opacity-0" initial={{ opacity: 0, x: 10 }} animate={{ x: location.pathname.indexOf("/projets") === 0 ? 0 : 10, opacity: location.pathname.indexOf("/projets") === 0 ? 1 : 0 }}>Projets</motion.li>
            <motion.li className="absolute opacity-0" initial={{ opacity: 0, x: 10 }} animate={{ x: location.pathname.indexOf("/contact") === 0 ? 0 : 10, opacity: location.pathname.indexOf("/contact") === 0 ? 1 : 0 }}>Contact</motion.li>
          </motion.ul>
        </div>
      </div>
      <TransitionGroup>
        <CSSTransition
          classNames='fade'
          timeout={500}
          in={isMounted}
          key={location.key}
        >
          <main>{children}</main>
        </CSSTransition>
      </TransitionGroup>
      <div className="top-0 bg-white fixed zindexnegatif w-screen h-screen"></div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default connect(state => ({
  loading: state.Loader.loading,
  scrollHeight: state.Scrollvalue.scrollHeight,
  LocationPath: state.LocationPath.path,
  validationForm: state.FormValidation.validation
}), null)(Layout);
