const initialState = {
    x: null,
    y: null,
};

export default function MousePosition(state = initialState, action) {
    switch (action.type) {
        case 'SetMousePosition':
            return state = {x: action.x, y: action.y};
        default:
            return state;
    }
}